import {
  AUTO,
  BIKE,
  FUNERAL,
  HOME,
  LIFE,
  MOTO,
  PET,
  SERIOUS_DISEAS,
  TECH_HOME,
  TECH_MOVILE,
  TRAVELS,
  PERSONAL_ACCIDENTS,
} from './product'

export const ProductEnum = {
  auto: 'auto',
  bici: 'bici',
  funeraria: 'funeraria',
  hogar: 'hogar',
  vida: 'vida',
  moto: 'moto',
  accidentes: 'accidentes',
  mascotas: 'mascotas',
  enfermedadesGraves: 'enfermedades-graves',
  techHome: 'tech-home',
  techMovile: 'tech-movile',
  viajes: 'viajes',
} as const

export type ProductCode = PropsTypesOf<typeof ProductEnum>

export const StoreProcessType = {
  QUOTE: 'QUOTE',
  PURCHASE: 'PURCHASE',
} as const

export const ProductPlanSubscriptionTypes = {
  unique: { text: 'Pago unico', value: 'unique', id: 0 },
  monthly: { text: 'Mensual', value: 'monthly', id: 1 },
  quarterly: { text: 'Trimestral', value: 'quarterly', id: 3 },
  fourMonthly: { text: 'Cuatrimestral', value: 'fourMonthly', id: 4 },
  yearly: { text: 'Anual', value: 'yearly', id: 12 },
} as const

export interface Product {
  productId?: number
  name: string
  tagline: string
  alias?: string
  useComparator?: boolean
  code: ProductCode
  process: PropsTypesOf<typeof StoreProcessType>
  bannerUrl: string
  iconUrl: string
  description: string
  highlights: ProductHighlights[]
  insurances: string[]
  compliances: string[]
  exceptions: string[]
  requirements: string[]
  plans?: ProductPlan[]
}

export interface ProductHighlights {
  title: string
  artWork: string
  description: string
}

export interface ProductPlanSumary {
  title?: string
  description: {
    label: string
    detail?: string
    type?: 'NOTE' | 'ITEM'
  }[]
}

export interface ProductPlan {
  name: string
  code: string
  description: string
  highlights: ProductPlanSumary
  price: number
  subscription: PropsTypesOf<typeof ProductPlanSubscriptionTypes>['value']
}

export interface Coverage {
  code: string
  name: string
  corePlanId: number
  coreCoberturaId: number
  subscriptions: CoverageSubscriptions[]
}

export interface CoverageSubscriptions {
  price: number
  subscription: 'ONESHOT' | 'DAILY' | 'WEEKLY' | 'QUARTERLY' | 'MONTHLY' | 'YEARLY'
  taxes: CoverageSubscriptionsTaxes
}

export interface CoverageSubscriptionsTaxes {
  code: string
  name: string
  amount: number
}

export const PRODUCTS: Product[] = [
  AUTO,
  //GAMER,
  //TECH,
  TECH_HOME,
  TECH_MOVILE,
  //LIFE,
  TRAVELS,
  //BIKE,
  //HOME,
  PET,
  //MOTO,
  FUNERAL,
  PERSONAL_ACCIDENTS,
  SERIOUS_DISEAS,
]
